import { addMinutes, parse } from 'date-fns';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

export function formatDate(date: number | Date) {
  return isValid(date) ? format(date, 'ddMMyyyy') : 'N/A';
}

export function parseDate(date: string) {
  if (date) {
    const [day, month, year] = date.split('/');

    const data = `${year}-${month}-${day}`;

    return data;
  }
}

export function formatarDataAno(date: string) {
  if (date) {
    if (date.indexOf('/') !== -1) {
      const [day, month, year] = date.split('/');
      const data = `${year}-${month}-${day}`;
      return data;
    }
    if (date.indexOf('-') !== -1) {
      const [day, month, year] = date.split('-');
      const data = `${year}-${month}-${day}`;
      return data;
    }
  }
}

export function DateFormate(date: string) {
  if (date) {
    return format(parse(date, 'MM-dd-yyyy', new Date()), 'dd/MM/yyyy');
  }
}

export function formatarData(date: string) {
  try {
    let parsedDate = parse(date, 'yyyy-MM-dd', new Date());
    parsedDate = addMinutes(parsedDate, parsedDate.getTimezoneOffset());
    return format(parsedDate, 'dd/MM/yyyy');
  } catch (err) {
    return null;
  }
}

export function formatTimestamp(timestamp: string) {
  let date = new Date(Number(timestamp));
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let year = date.getFullYear();
  let hours = (date.getHours() - 5).toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
