import axios from 'axios';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { EnvVars } from 'env';

export function setupAPIClient(ctx = undefined) {
  let cookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: EnvVars.API_URL,
    headers: {
      Authorization: `Bearer ${cookies['meunascimento.token']}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      cookies = parseCookies(ctx);
      const { 'meunascimento.refreshToken': refreshToken } = cookies;
      const originalRequest = err.config;
      if (err.response?.status === 401 && err.response?.data?.message === 'token expired') {
        const response = await api.put('/auth', {
          refresh_token: refreshToken,
        });
        const { accessToken } = response.data;
        setCookie(ctx, 'meunascimento.token', accessToken, {
          maxAge: 60 * 60 * 24 * 30, // 30 days
          path: '/',
        });

        setCookie(ctx, 'meunascimento.refreshToken', response.data.refreshToken, {
          maxAge: 60 * 60 * 24 * 30, // 30 days
          path: '/',
        });

        api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
        return api({
          ...originalRequest,
          headers: { ...originalRequest.headers, Authorization: `Bearer ${accessToken}` },
        });
      }
      if (typeof window === 'undefined') {
        destroyCookie(undefined, 'meunascimento.token');
        destroyCookie(undefined, 'meunascimento.refreshToken');
      }
      return Promise.reject(err);
    },
  );
  return api;
}
