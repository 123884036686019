import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { EnvVars } from 'env';

type ReCAPTCHAProps = {
  recaptcha: string;
  handleVerifyRecaptcha: (token: string) => void;
  refreshRecaptcha: string | number | boolean;
  setRefreshRecaptcha: (state: string | number | boolean) => void;
};

type ReCAPTCHAProviderProps = {
  children: ReactNode;
};

const ReCAPTCHAContext = createContext<ReCAPTCHAProps>({} as ReCAPTCHAProps);

export function ReCAPTCHAProvider({ children }: ReCAPTCHAProviderProps) {
  const { RECAPTCHA_SITE_KEY } = EnvVars;
  const [recaptcha, setRecaptcha] = useState('');
  const [refresh, setRefresh] = useState<string | number | boolean>(Date.now());

  const handleVerifyRecaptcha = useCallback((token) => {
    setRecaptcha(token);
  }, []);

  const setRefreshRecaptcha = useCallback((state: string | number | boolean) => {
    setRefresh(state);
  }, []);

  useEffect(() => {
    setInterval(() => {
      setRefreshRecaptcha(Date.now());
    }, 90000);
  }, [setRefreshRecaptcha]);

  return (
    <ReCAPTCHAContext.Provider
      value={{
        handleVerifyRecaptcha,
        recaptcha,
        refreshRecaptcha: refresh,
        setRefreshRecaptcha,
      }}
    >
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY ?? ''}>{children}</GoogleReCaptchaProvider>
    </ReCAPTCHAContext.Provider>
  );
}

export function useReCAPTCHA(): ReCAPTCHAProps {
  const context = useContext(ReCAPTCHAContext);

  if (!context) {
    throw new Error('useReCAPTCHA must be used within an ReCAPTCHAProvider');
  }

  return context;
}
