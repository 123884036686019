import { Dispatch, SetStateAction, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { api } from 'services/apiClient';
import Link from './Link';

interface IModalProps {
  modalIsOpen: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  showLoginButton?: boolean;
}

export function AlertCPFAlreadyExists({ modalIsOpen, closeModal, showLoginButton = true }: IModalProps) {
  const [passwordRecoveryModalIsOpen, setPasswordRecoveryModalIsOpen] = useState(false);
  const [cpf, setCpf] = useState('');
  const [status, setStatus] = useState('');
  const [maskedEmail, setMaskedEmail] = useState('');

  const handleModal = () => {
    closeModal(false);
  };

  const openPasswordRecoveryModal = () => {
    setPasswordRecoveryModalIsOpen(true);
  };

  const closePasswordRecoveryModal = () => {
    setPasswordRecoveryModalIsOpen(false);
  };

  const recoverPassword = async () => {
    try {
      const response = await api.post('/users/password', {
        document: cpf,
      });

      if (response.status === 200) {
        const { maskedEmail } = response.data;
        setMaskedEmail(maskedEmail);
        setStatus('completed');
      } else {
        console.log('Erro!');
      }
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 400) {
          setStatus('missing-info');
        } else if (error.response.status === 404) {
          setStatus('user-not-found');
        }
      } else {
        console.error('Erro na solicitação:', error);
        setStatus('error');
      }
    }
  };

  const renderModalContent = () => {
    if (status === 'completed') {
      return (
        <div>
          <CompletedResponse>Email enviado para: {maskedEmail}</CompletedResponse>
        </div>
      );
    } else if (status === 'missing-info') {
      return (
        <div>
          <ErrorResponse>Por favor, forneça um documento.</ErrorResponse>
        </div>
      );
    } else if (status === 'user-not-found') {
      return (
        <div>
          <ErrorResponse>Usuário não encontrado.</ErrorResponse>
        </div>
      );
    } else if (status === 'error') {
      return (
        <div>
          <p>Ocorreu um erro na solicitação, tente novamente.</p>
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleModal}
        ariaHideApp={false}
        style={{
          overlay: {
            width: '100%',
            zIndex: 9999999,
            margin: ' auto -40px',
          },
          content: {
            maxWidth: '550px',
            maxHeight: '250px',
            width: '90%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '15px',
            margin: '200px auto',
            padding: '10px',
            position: 'relative',
          },
        }}
      >
        <CloseButton onClick={handleModal}>X</CloseButton>
        <ContainerInformation>
          <div>
            <h1>
              Você já possui cadastro em nossa plataforma,{' '}
              <Link href={'https://meunascimento.com.br/'}>
                <strong>Meu nascimento</strong>
              </Link>
              . Escolha uma das opções abaixo
            </h1>
          </div>

          <Buttons>
            <button onClick={openPasswordRecoveryModal}>Esqueci minha senha</button>

            {showLoginButton && (
              <Link href={'/login'}>
                <button onClick={handleModal}>Entrar em minha conta</button>
              </Link>
            )}
          </Buttons>
        </ContainerInformation>
      </Modal>

      {passwordRecoveryModalIsOpen && (
        <Modal
          isOpen={passwordRecoveryModalIsOpen}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={closePasswordRecoveryModal}
          ariaHideApp={false}
          style={{
            overlay: {
              width: '100%',
              zIndex: 9999999,
              margin: ' auto -10px',
            },
            content: {
              maxWidth: '400px',
              maxHeight: '300px',
              width: '85%',
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '15px',
              margin: '150px auto',
              padding: '10px',
            },
          }}
        >
          <CloseButton onClick={handleModal}>X</CloseButton>
          <ContainerInformation>
            <h1>Informe seu documento (CPF) para recuperar sua senha!</h1>

            <input placeholder="Informe seu CPF" type="string" value={cpf} onChange={(e) => setCpf(e.target.value)} />

            {status && <div>{renderModalContent()}</div>}

            <Buttons>
              <button onClick={recoverPassword}>Recuperar</button>
            </Buttons>
          </ContainerInformation>
        </Modal>
      )}
    </>
  );
}

const ErrorResponse = styled.div`
  color: red;
  font-size: 15px;
  margin-top: 10px;
`;

const CompletedResponse = styled.div`
  color: green;
  font-size: 15px;
  margin-top: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 25px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
`;

const ContainerInformation = styled.div`
  max-width: 758px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;

  strong {
    color: #5b5db5;
  }

  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin: 15px;
  }

  input {
    border: none;
    border: solid 1px #000;
    padding: 10px;
    font-size: 15px;
    border-radius: 8px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  button {
    background: #5b5db5;
    padding: 10px;
    margin-right: 10px;
    color: #fff;
    border: none;
    border-radius: 7px;
    font-size: 15px;
  }

  button:hover {
    opacity: 0.9;
  }
`;
